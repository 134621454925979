<template>
  <section class="missing">
    <div class="missing-message section">
      <h1>404</h1>
      <h5>Такої сторінки не існує</h5>
      <p>Схоже, це не правильна адреса, або сторінка переїхала</p>

      <router-link class="button" to="/">На головну</router-link>
    </div>
  </section>
</template>
<script>
  export default {
    name: "Missing",
  };
</script>

<style lang="scss">
  .missing-message {
    display: grid;
    place-items: center;
    padding-top: 64px;
  }
</style>
